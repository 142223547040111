import React from "react";
import styles from "./index.module.scss";
import { Typography } from "@crocoder-dev/components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function ComponentLibraryTitle({ libraries, id, children }) {
  const library = libraries.find((library) => library.id === id);
  const { title, altTitle, image } = library;

  return (
    <div id={id} className={styles.libraryTitleWrapper}>
      <div className={styles.libraryLogo}>
        <Img
          fadeIn={false}
          fluid={image ? image.childImageSharp.fluid : {}}
          alt={title}
        />
      </div>
      <Typography className={styles.libraryTitle} element="h2">
        {children}
      </Typography>
      <Typography
        className={styles.libraryAltTitle}
        fontFamily="rubik"
        element="p"
        fontSize={20}
      >
        "{altTitle}"
      </Typography>
    </div>
  );
}

const ComponentLibraryWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        componentLibrariesJson {
          componentLibraries {
            id
            title
            altTitle
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <ComponentLibraryTitle
        {...props}
        libraries={data.componentLibrariesJson.componentLibraries}
      />
    )}
  />
);

export default ComponentLibraryWithQuery;
