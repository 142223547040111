import React from "react";
import styles from "./index.module.scss";
import DemoIcon from "../../../../images/icons/demo.svg";

const Demo = ({ children, hide }) => {
  console.log(hide, children);
  return (
    <div className={styles.demo}>
    {!!hide || <DemoIcon className={styles.icon} /> }
    <div className={styles.content}>{children}</div>
  </div>
  )
};

export default Demo;
