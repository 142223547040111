import React from "react";
import rehypeReact from "rehype-react";
import Demo from "../CodeExample/Demo";
import Snippet from "../CodeExample/Snippet";
import Row from "../CodeExample/Row";
import Column from "../CodeExample/Column";
import ListToggle from "../CodeExample/ListToggle";
import Subtitle from "../Subtitle";
import { Typography } from "@crocoder-dev/components";
import StatusQuoDemo from "../ImperativeHandlePost/StatusQuoDemo";
import UncontrolledDemo from "../ImperativeHandlePost/UncontrolledDemo";
import SectionTitle from "../SectionTitle";
import QuoteCard from "../QuoteCard";
import ComponentLibraryTitle from "../ComponentLibraryTitle";

const imperativeHandlePost = {
  "status-quo-demo": StatusQuoDemo,
  "uncontrolled-demo": UncontrolledDemo,
};

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "component-library-title": ComponentLibraryTitle,
    "quote-card": QuoteCard,
    demo: Demo,
    snippet: Snippet,
    column: Column,
    row: Row,
    "list-toggle": ListToggle,
    typography: Typography,
    subtitle: Subtitle,
    "section-title": SectionTitle,
    ...imperativeHandlePost,
  },
}).Compiler;

export default renderAst;
