import React from "react";
import styles from "./index.module.scss";
import { Typography, Section } from "@crocoder-dev/components";
import { StaticQuery, graphql } from "gatsby";
import classnames from "classnames";
import Img from "gatsby-image";

const QuoteCard = ({
  direction,
  children,
  "author-color": authorBgColor,
  "author-name": authorName,
  "author-role": authorRole,
  authors,
}) => {
  const { name, image } = authors.find((author) => author.id === authorName);

  return (
    <Section
      backgroundColor="blue_6"
      className={classnames(styles.quoteCard, {
        [styles.leftDirection]: direction !== "right",
      })}
    >
      <figure className={styles.figureWrapper}>
        <div
          style={{ backgroundColor: authorBgColor }}
          className={styles.authorImageWrapper}
        >
          <Img
            className={styles.authorImage}
            fadeIn={false}
            fluid={image ? image.childImageSharp.fluid : {}}
            alt={name}
          />
        </div>
        <figcaption className={styles.authorDetails}>
          <Typography
            className={styles.author}
            element="p"
            fontFamily="rubik"
            fontWeight={700}
            fontSize={22}
          >
            {name}
          </Typography>
          <Typography
            className={styles.role}
            element="p"
            fontFamily="rubik"
            fontWeight={500}
            fontSize={14}
          >
            {authorRole}
          </Typography>
        </figcaption>
        <Typography
          fontFamily="rubik"
          fontWeight={300}
          element={"blockquote"}
          fontSize={22}
          className={styles.quoteBody}
        >
          {children}
        </Typography>
      </figure>
    </Section>
  );
};

const QuoteCardWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        authorsJson {
          authors {
            id
            name
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <QuoteCard {...props} authors={data.authorsJson.authors} />
    )}
  />
);

export default QuoteCardWithQuery;
