import React from "react";
import { Typography } from "@crocoder-dev/components";

const SectionTitle = ({id, children}) => {
  return (
    <>
      <Typography id={id} element="h2">{children}</Typography>
    </>
  );
}

export default SectionTitle;